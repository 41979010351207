import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Move your first steps with Satiurn's Suite",
  "date": "2022-04-08T00:00:00.000Z",
  "slug": "move-your-first-steps-with-satiurn",
  "description": "Move your fist steps with Satiurn's Suite and skyrocket your productivity 🚀",
  "tags": ["Generic"],
  "banner": "./banner.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>Move your fist steps with Satiurn's Suite and skyrocket your productivity 🚀</h3>
    <p>{`Satiurn is a powerful business management tool that helps freelancers and small companies to better organize everything related to them.`}</p>
    <p>{`Let’s start with the main workflow that was designed to be perfect for a Satiurn user or check all our `}<a parentName="p" {...{
        "href": "/blog"
      }}>{`tutorials`}</a>{`.`}</p>
    <h5>Setup Satiurn to become hand made for you</h5>
    <p>{`Connect your preferred payment gateway, insert your branding, customize Satiurn with your own domain, and many other customization features can be found in the Satiurn settings section.
Those are necessary for your business to become as efficient as possible and are the fundaments from where you have to start.`}</p>
    <p>{`Learn more about Satiurn settings `}<a parentName="p" {...{
        "href": "/tags/settings"
      }}>{`here`}</a></p>
    <h5>Create a new customer contact</h5>
    <p>{`Saving all your customers on Satiurn will save you a lot of time, in fact, once Satiurn memorizes all the customer information you will be able to get precompiled proposals and rapidly search between all of them for the right one.`}</p>
    <ol>
      <li parentName="ol">{`Go to the Contacts section.`}</li>
      <li parentName="ol">{`Click on the “Create contact button then you should at least write a Name and a Surname or Principal name all the other fields are optional.`}</li>
      <li parentName="ol">{`Click on the “Create” button to save the new customer and proceed to the next section`}</li>
    </ol>
    <p>{`Learn more about contacts `}<a parentName="p" {...{
        "href": "/tags/contacts"
      }}>{`here`}</a>{`.`}</p>
    <h5>Write a proposal for your customer</h5>
    <p>{`Creating a quote for your customer on Satiurn will be super easy and fast! With our powerful editor you can also personalize every section of your document.`}</p>
    <ol>
      <li parentName="ol">{`Go to the Proposals section.`}</li>
      <li parentName="ol">{`Click on the “Create Proposal” button, select the previously saved customer, to prefill all the fields automatically.`}</li>
      <li parentName="ol">{`Specify a title and a period estimated for this work.`}</li>
      <li parentName="ol">{`You can also add more registries, in case you need to remember for example who collaborate with you on that project.`}</li>
      <li parentName="ol">{`Insert item list, also once the total price is out, you can set a down payment to be displayed.`}</li>
    </ol>
    <p>{`Learn more about proposals `}<a parentName="p" {...{
        "href": "/tags/proposals"
      }}>{`here`}</a>{`.`}</p>
    <h5>Export your quote or share it directly from Satiurn</h5>
    <ol>
      <li parentName="ol">{`Go to the Proposals section.`}</li>
      <li parentName="ol">{`Click on the “Share” button of the quote that you want to export or share.`}</li>
      <li parentName="ol">{`With the editor, you can personalize every quote as you wish.`}</li>
      <li parentName="ol">{`Once you are happy with the result, export the quote in pdf format or if you prefer, share the link to access the quote without downloading it and check when your customer sees it.`}</li>
    </ol>
    <h5>Create the project board</h5>
    <p>{`And here we come to one of the interesting parts of the workflow, the board creation. Satiurn’s boards help you to better organize all the projects you are running with.`}</p>
    <ol>
      <li parentName="ol">{`Go to the Boards Section.`}</li>
      <li parentName="ol">{`Click on the “Create board” button and choose a name for the board.`}</li>
      <li parentName="ol">{`Now you can start adding custom lists to your board to divide all the tasks as you prefer.`}</li>
      <li parentName="ol">{`Write for each list the task that has to be done.`}</li>
      <li parentName="ol">{`Mark them as done when you have finished your job.`}</li>
      <li parentName="ol">{`Use the timeline to see if you are into the project deadline.`}</li>
    </ol>
    <p>{`Learn more about projects `}<a parentName="p" {...{
        "href": "/tags/projects"
      }}>{`here`}</a>{`.`}</p>
    <h5>Track your times</h5>
    <p>{`For each task, you will be able to track how much time was necessary to get the job done. Use this powerful tool to understand if your quote was right or maybe you have to change your pricing for the next times, or use it with the weekly planner to get a full vision of you and your teammate fatigue burst.`}</p>
    <ol>
      <li parentName="ol">{`Once a task has been created click on it.`}</li>
      <li parentName="ol">{`On the right part of the modal, you can start a Chronometer or set it manually.`}</li>
      <li parentName="ol">{`Once stopped or saved you will see how much of the estimated time has been used.`}</li>
      <li parentName="ol">{`Now you can think about adding more time or understanding why are you using more time than expected.`}</li>
    </ol>
    <p>{`Learn more about how to organize your work and use the planner `}<a parentName="p" {...{
        "href": "/tags/planner"
      }}>{`here`}</a>{`.`}</p>
    <h5>Annotate your transaction</h5>
    <p>{`During your projects, you may spend some money on material or get money from small works that don’t need a proposal to be done. You can directly add them from the Finance section.`}</p>
    <ol>
      <li parentName="ol">{`Go to the Finance section.`}</li>
      <li parentName="ol">{`Click on the “New Transaction” button and start filling the main fields like Title, Date, Category, and Amount are necessary to create a new transaction.`}</li>
      <li parentName="ol">{`If you already completed it set as paid the transaction.`}</li>
      <li parentName="ol">{`Finally, click on the “Create” button to save it on Satiurn.`}</li>
    </ol>
    <p>{`Learn more about the finance section `}<a parentName="p" {...{
        "href": "/tags/finance"
      }}>{`here`}</a>{`.`}</p>
    <h5>Send a payment request</h5>
    <p>{`Once the job is finished, get paid directly from Satiurn!
The only thing that you have to do is connect your `}<a parentName="p" {...{
        "href": "/how-to-setup-stripe-connect-on-satiurn"
      }}>{`Stripe`}</a>{` or `}<a parentName="p" {...{
        "href": "/how-to-setup-paypal-on-satiurn"
      }}>{`Paypal`}</a>{` account to Satiurn and send the payment request directly from the Proposals section.`}</p>
    <h5>Work with your team wherever you are</h5>
    <p>{`If you have a team that is working with you, don’t forget to add them to your business.
You can add them grating permission for the single board or for each of all the business sections.`}</p>
    <ol>
      <li parentName="ol">{`Go to the Users section.`}</li>
      <li parentName="ol">{`Click on the “Invite user” button to insert an email where the invitation code will be sent, your teammate not necessarily needs to use that email to sign up on Satiurn.`}</li>
      <li parentName="ol">{`Set the permissions for each section or if you prefer, remove all permission from the generic business and add them only to their board. In this way, they will only see Proposals, Transactions, or other things that are related to that board.`}</li>
    </ol>
    <p>{`Learn more about how to work in team `}<a parentName="p" {...{
        "href": "/tags/team"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      