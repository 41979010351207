import { graphql, useStaticQuery } from "gatsby";

type UsePosts = any;

const usePosts = () => {
  const data = useStaticQuery<UsePosts>(graphql`
    query {
      allPost {
        nodes {
          canonicalUrl
          tags {
            name
            slug
          }
          date
          description
          excerpt
          id
          slug
          timeToRead
          title
          banner {
            publicURL
          }
        }
      }
    }
  `);

  return data.allPost.nodes;
};

export default usePosts;
