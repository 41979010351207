import React from "react";
import Homepage from "../../../components/homepage";
import usePosts from "../../../hooks/use-posts";

type Props = {
  data: {
    [key: string]: string;
  };
  [key: string]: any;
};

export default function MinimalBlogCoreHomepage({ ...props }: Props) {
  const nodes = usePosts();

  return <Homepage posts={nodes} {...props} />;
}
