/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql, Link } from "gatsby";
import Layout from "./layout";
import Title from "./title";
import Listing from "./listing";
import List from "./list";
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config";
import useSiteMetadata from "../hooks/use-site-metadata";
import replaceSlashes from "../utils/replaceSlashes";
import { visuallyHidden } from "../styles/utils";
// @ts-ignore
import Hero from "../texts/hero";
// @ts-ignore
import Hero2 from "../../content/posts/OTHERS_move-your-first-steps-with-Satiurn";
// @ts-ignore
import Bottom from "../texts/bottom";
import usePosts from "../hooks/use-posts";

type PostsProps = {
  posts: any[];
  [key: string]: any;
};

const Homepage = ({ posts }: PostsProps) => {
  const { basePath, blogPath } = useMinimalBlogConfig();
  const { siteTitle } = useSiteMetadata();
  const tagsList = [];
  posts.forEach((post) => {
    post.tags.forEach((tag) => {
      if (!tagsList.map((tag) => { return tag.slug }).includes(tag.slug)) {
        tagsList.push(tag);
      }
    })
  })
  console.log(tagsList);
  const customPost = posts.filter((post) => { return post.title.includes("Move your first") });
  return (
    // <Layout>
    //   <h1 sx={visuallyHidden}>{siteTitle}</h1>
    //   <h1>Getting Started</h1>
    //   <Listing posts={customPost} showTags={false} />
    //   <h2>Tutorials</h2>
    //   <Listing posts={posts} showTags={false} />
    //   <List sx={{ variant: `section_bottom` }}>
    //     <Bottom />
    //   </List>
    // </Layout>
    <Layout>
      <section
        sx={{
          mb: [5, 6, 7],
          p: { fontSize: [1, 2, 3], mt: 2 },
          variant: `section_hero`,
        }}
      >
        <Hero2 />
      </section>
    </Layout>
  );
};

export default Homepage;

/*
<Title text="Popular Tutorials">
        <Link to={replaceSlashes(`/${basePath}/${blogPath}`)}>
          Read all tutorials
        </Link>
      </Title>
<section
  sx={{
    mb: [5, 6, 7],
    p: { fontSize: [1, 2, 3], mt: 2 },
    variant: `section_hero`,
  }}
>
  <Hero />
</section>*/